import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import { useNavigate } from "react-router";
import { updateUser } from "../../../services/userManagement";
import swotBuddyCardImg1 from "../../../assets/images/SwotBuddy/swotbuddy_first-card.svg";
import swotBuddyCardImg2 from "../../../assets/images/SwotBuddy/swotbuddy_second-card.svg.svg";

const MainComponent = ({ title, image, description, onClick, children }) => {
  return (
    <div className="w-full max-w-72 min-h-44 h-1/3 md:h-[23rem] lg:h-[27rem] p-4 lg:p-8">
      <div
        className="w-full h-full flex flex-col bg-white rounded-xl shadow-lg overflow-hidden cursor-pointer justify-end"
        onClick={() => {
          onClick();
        }}
      >
        <div className="flex-1 w-full">
          <img
            className="object-contain p-7 h-full w-full"
            src={image}
            alt={title}
          />
        </div>
        <div className="p-4">
          <h2 className="text-2xl font-bold text-center">{title}</h2>
          <p className="mt-2 text-sm text-center">{description}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

const SwotBuddySetUp = ({ user, saveUser }) => {
  let navigate = useNavigate();

  return (
    <div className="w-full h-full">
      <div className="w-full text-center h-[2rem]">
        <p className="text-white font-normal">
          Please choose, if you want a{" "}
          <span className="text-base font-bold">personalized</span> experience,
          meaning your studybuddy will teach you the way you learn best. <br />
          <span className="text-base font-bold">Default,</span> no
          personalization will be done, but you still will be getting a super
          studybuddy experience. 
        </p>
      </div>
      <div className="w-full h-[calc(100%-2rem)] mt-48 md:mt-0 flex justify-center items-start md:items-center">
        <div className="flex flex-col md:flex-row justify-around md:items-center">
          <MainComponent
            title="Personalized"
            image={swotBuddyCardImg2}
            description="Let our AI guide you and ensure your best experience"
            onClick={() => {
              const updatedUser = { ...user, swotBuddySetup: "personalized" };
              saveUser(updatedUser);
              navigate("/dashboard");
            }}
          />
          <MainComponent
            title="Default"
            image={swotBuddyCardImg1}
            description="Start by exploring everything yourself; you can always pursue a personalized journey later on"
            onClick={() => {
              const updatedUser = { ...user, swotBuddySetup: "default" };
              saveUser(updatedUser);
              updateUser(updatedUser, true);
              navigate("/study-buddy");
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwotBuddySetUp);
