import React from "react";
import SignIn from "../signin/signin";
import ProfilePage from "../profile/profile";
import Dashboard from "../dashboard/dashboard";
import PasswordReset from "../passwordReset/passwordReset";
import SetNewPassword from "../passwordReset/setNewPassword";
import OnBoarding from "../onBoarding/onBoarding";
import PrivateRoute from "../../components/navigation/privateRoute";
import EmailActivationPrompt from "../emailActivationPrompt/emailActivationPrompt";
import TutorSetup from "../tutorSetup/tutorSetup";
import School from "../school/school";
import Courses from "../courses/coursesDashboard";
import EditCourses from "../courses/coursesTutor/createCourse/editCourse";
import Calendar from "../calendar/calendar";
import SearchTutors from "../searchTutors/searchTutors";
import TutorTools from "../tools/tutorTools";
import StudentTools from "../tools/studentTools";
import AdvanceTools from "../tools/advanceTools";
import Guestfeed from "../guest/guestFeed";
import Information from "../landing/information";
import EducationEvo from "../landing/educationEvo";
import Subscription from "../landing/subscription";
import Swotnews from "../landing/swotnews";
import WhatWeBuilding from "../landing/whatWeBuilding";
import About from "../landing/about";
import TheTeam from "../landing/theTeam";

import VideoTutorials from "../videoTutorials/videoTutorials";
// import ForWho from "../landing/forWho";
// // Persona Pages
// import Home from "../landing/home";
import Home from "../../screens/newLanding/newLanding";
import HomeSchoolingPage from "../landing/PersonaPages/homeSchooling";
import StLearningDf from "../landing/PersonaPages/stLearningDf";
import ExamPrep from "../landing/PersonaPages/examPrep";
import PrChildLearningDf from "../landing/PersonaPages/prChildLearningDf";
import ForWhoTutors from "../landing/PersonaPages/forWhoTutors";
// End of Persona Pages

import BlogPosts from "../landing/blogPosts";
import LandingFAQ from "../landing/Landing_FAQ/landing_faq";
import FAQWithLoggedIn from "../faq/insideFAQ";
import LearningStyleQuiz from "../learningStyleQuiz/learningStyleQuiz";
import TedSearch from "../searchTutors/TedSearch";
import MentalHealth from "../mentalHealth/mentalHealth";
import ChatPrompt from "../chatPrompt/chatPrompt";
import Jobs from "../landing/Jobs";

// Ambassador Pages
import SignInAmbassador from "../ambassadorScreens/singInScreen/signinAmbassador";
import AmbassadorUploadPage from "../ambassadorScreens/upload";
import AmbassadorUserTable from "../ambassadorScreens/viewtable";
import AmbassadorCoupons from "../ambassadorScreens/coupons";
// End of Ambassador Pages

import Refund from "../refund/refund";
import Betatester from "../betatester/betatester";
import Ambassadors from "../ambassadors/ambassadors";
import Importer from "../importer/importer";
import UserManagementPage from "../userManagement/userManagement";
import BlogDetail from "../landing/blogDetail";
import CreateCourse from "../courses/coursesTutor/createCourse/createCourse";

import { CURRENT_RELEASE_MODE, ReleaseMode } from "../../config/constants";
import H5pApp from "../../h5p/H5pApp";
import ChatScreenComponent from "../../chat/components/ChatScreenComponent";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { historyState } from "../../services/navigation/history";
import BoxLoader from "../../components/common/BoxLoader";
import commonColor from "../../theme/commonColor";
import swotBuddyScreen from "../../components/student/swotBuddy/swotBuddyScreen";
import SwotBuddySetUp from "../../components/student/swotBuddy/swotBuddySetUp";
import SearchTutorsOpen from "../searchTutors/searchTutorsOpen";
import TermsAndConditions from "../terms-conditions/TermsAndConditions.js";
import PrivacyPolicy from "../privacy-policy/PrivacyPolicy.js";

function AppRouter() {
  const NotFound = () => <div>Sorry, nothing here.</div>;

  const landingPaths = [
    {
      path: "/what-we-offer",
      element: <EducationEvo />,
    },

    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/video-tutorials",
      element: <VideoTutorials />,
    },
    {
      path: "/information",
      element: <Information />,
    },
    {
      path: "/plans",
      element: <Subscription />,
    },
    {
      path: "/news",
      element: <Swotnews />,
    },
    {
      path: "/what-we-are-building",
      element: <WhatWeBuilding />,
    },
    {
      path: "/learner-type",
      element: <WhatWeBuilding />,
    },
    {
      path: "/learner-type-new",
      element: <WhatWeBuilding />,
    },
    {
      path: "/about-us",
      element: <About />,
    },
    {
      path: "/the-team",
      element: <TheTeam />,
    },
    {
      path: "/home-schooling",
      element: <HomeSchoolingPage />,
    },
    {
      path: "/st-learning-df",
      element: <StLearningDf />,
    },
    {
      path: "/exam-prep",
      element: <ExamPrep />,
    },
    {
      path: "/pr-children-learning-df",
      element: <PrChildLearningDf />,
    },
    {
      path: "/for-tutors",
      element: <ForWhoTutors />,
    },
    {
      path: "/frequent-asked-questions",
      element: <LandingFAQ />,
    },
    {
      path: "/blogs/detail/:blogId",
      element: <BlogDetail />,
    },
    {
      path: "/blogs",
      element: <BlogPosts />,
    },
    {
      path: "/quiz",
      element: <LearningStyleQuiz />,
    },
    {
      path: "/Jobs",
      element: <Jobs />,
    },
    {
      path: "/frequent",
      element: <LandingFAQ />,
    },
    {
      path: "courses/:courseId",
      element: <Courses />,
    },
    {
      path: "search-tutors",
      element: <SearchTutorsOpen />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsAndConditions />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
  ];

  var mainPaths = [
    {
      path: "dashboard",
      element: <PrivateRoute as={Dashboard} />,
    },
    {
      path: "/dashboard",
      element: <PrivateRoute as={Dashboard} />,
    },
    {
      path: "tutor-setup",
      element: <PrivateRoute as={TutorSetup} />,
    },
    {
      path: "school",
      element: <PrivateRoute as={School} />,
    },
    {
      path: "courses",
      element: <PrivateRoute as={Courses} />,
    },
    {
      path: "edit-course",
      element: <PrivateRoute as={EditCourses} />,
    },
    {
      path: "edit-course/:courseId",
      element: <PrivateRoute as={EditCourses} />,
    },
    {
      path: "calendar",
      element: <PrivateRoute as={Calendar} />,
    },
    {
      path: "on-boarding",
      element: <OnBoarding />,
    },
    {
      path: "onBoarding",
      element: <OnBoarding />,
    },
    {
      path: "sign-in",
      element: <SignIn />,
    },
    {
      path: "signIn",
      element: <SignIn />,
    },
    {
      path: "password-reset",
      element: <PasswordReset />,
    },
    {
      path: "passwordReset",
      element: <PasswordReset />,
    },
    {
      path: "set-new-password",
      element: <SetNewPassword />,
    },
    {
      path: "setNewPassword",
      element: <SetNewPassword />,
    },
    {
      path: "find-tutors",
      element: <PrivateRoute as={SearchTutors} />,
    },
    {
      path: "tutor-tools",
      element: <PrivateRoute as={TutorTools} />,
    },
    {
      path: "study-buddy",
      element: <PrivateRoute as={swotBuddyScreen} />,
    },
    {
      path: "student-tools",
      element: <PrivateRoute as={StudentTools} />,
    },
    {
      path: "advance-tools",
      element: <PrivateRoute as={AdvanceTools} />,
    },
    {
      path: "guest-feed",
      element: <PrivateRoute as={Guestfeed} />,
    },
    {
      path: "faqs",
      element: <PrivateRoute as={FAQWithLoggedIn} />,
    },
    {
      path: "refund",
      element: <PrivateRoute as={Refund} />,
    },
    {
      path: "betatester",
      element: <PrivateRoute as={Betatester} />,
    },
    {
      path: "ambassadors",
      element: <PrivateRoute as={Ambassadors} />,
    },

    {
      path: "importer",
      element: <PrivateRoute as={Importer} />,
    },
    {
      path: "user-management",
      element: <PrivateRoute as={UserManagementPage} />,
    },
    {
      path: "create-course",
      element: <PrivateRoute as={CreateCourse} />,
    },
    {
      path: "ted-search",
      element: <PrivateRoute as={TedSearch} />,
    },
    {
      path: "mental-health",
      element: <PrivateRoute as={MentalHealth} />,
    },
    {
      path: "chat-prompt",
      element: <PrivateRoute as={ChatPrompt} />,
    },
    {
      path: "content-management",
      element: <PrivateRoute as={H5pApp} />,
    },
    {
      path: "profile",
      element: <PrivateRoute as={ProfilePage} />,
    },
    {
      path: "profile/messages",
      element: <PrivateRoute as={ChatScreenComponent} />,
    },

    {
      path: "activation",
      element: <PrivateRoute as={EmailActivationPrompt} />,
    },

    /// Ambassador Pages
    {
      path: "/ambassador",
      element: <SignInAmbassador />,
    },

    {
      path: "/userUpload",
      element: <AmbassadorUploadPage />,
    },

    {
      path: "/viewUsers",
      element: <AmbassadorUserTable />,
    },

    {
      path: "/coupons",
      element: <AmbassadorCoupons />,
    },

    /// Ambassador Pages - END
    {
      path: "/swotBuddySetup",
      element: <SwotBuddySetUp />,
    },
  ];

  var defaultpath = [
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const routes = createBrowserRouter([
    ...landingPaths,
    ...(CURRENT_RELEASE_MODE !== ReleaseMode.BETA ? mainPaths : []),
    ...defaultpath,
  ]);

  historyState.navigate = routes.navigate;
  // historyState.location =routes.state.location;
  // historyState.router = routes;

  // const FallbackElement = () => {
  //   <div className="h-screen w-screen">
  //   <div className="w-full h-full flex justify-center bg-white text-black items-center">
  //     <CustomFadeLoader loading={true} color={commonColor.deepGreen} />
  //   </div>
  // </div>
  // }

  return (
    <RouterProvider
      router={routes}
      fallbackElement={
        <BoxLoader color={commonColor.deepGreen} loading={true} />
      }
    />
    // <Routes>
    //   <Route element={<EducationEvo />} path="/" />
    //   <Route element={<Information />} path="/information" />
    //   <Route element={<Subscription />} path="/plans" />
    //   <Route element={<Swotnews />} path="/news" />
    //   <Route element={<WhatWeBuilding />} path="/what-we-are-building" />
    //   <Route element={<WhatWeBuilding />} path="/learner-type" />
    //   <Route element={<Redirect from="/learner-type-new" to="/learner-type" /> //->
    //   <Route element={<About />} path="/about-us" />
    //   <Route element={<TheTeam />} path="/the-team" />
    //   {/*
    //   <ForWho path="/for-who" /> */}
    //   {/* PersonaPages */}
    //   <HomeSchoolingPage path="/home-schooling" />
    //   <StLearningDf path="/st-learning-df" />
    //   <ExamPrep path="/exam-prep" />
    //   <PrChildLearningDf path="/pr-children-learning-df" />
    //   <ForWhoTutors path="/for-tutors" />
    //   {/* EndOfPersonaPages */}

    //   <LandingFAQ path="/frequent-asked-questions" />
    //   <BlogDetail path="/blogs/detail/:blogId" />
    //   <BlogPosts path="/blogs" />
    //   <LearningStyleQuiz path="quiz" />
    //   <Jobs path="Jobs" />

    //   {CURRENT_RELEASE_MODE !== ReleaseMode.BETA && (
    //     <>
    //       {/* <PrivateRoute as={Landing_FAQ} path="/frequent_asked_questions" /> */}
    //       <PrivateRoute as={Dashboard} path="dashboard" />
    //       <PrivateRoute as={TutorSetup} path="tutor-setup" />
    //       <PrivateRoute as={School} path="school" />
    //       {/* <PrivateRoute as={Courses} path="courses/:courseId" /> */}
    //       <Courses path="courses/:courseId" />
    //       <PrivateRoute as={Courses} path="courses" />
    //       {/* <PrivateRoute as={CourseDetailScreen} path="courses/detail/:courseId" /> */}
    //       <PrivateRoute as={EditCourses} path="edit-course" />
    //       <PrivateRoute as={EditCourses} path="edit-course/:courseId" />
    //       <PrivateRoute as={Calendar} path="calendar" />
    //       <PrivateRoute as={SearchTutors} path="find-tutors" />
    //       <PrivateRoute as={TutorTools} path="tutor-tools" />
    //       <PrivateRoute as={StudentTools} path="student-tools" />
    //       <PrivateRoute as={AdvanceTools} path="advance-tools" />
    //       <PrivateRoute as={Guestfeed} path="guest-feed" />
    //       <PrivateRoute as={FAQWithLoggedIn} path="faqs" />
    //       <PrivateRoute as={Refund} path="refund" />
    //       <PrivateRoute as={Betatester} path="betatester" />
    //       <PrivateRoute as={Importer} path="importer" />
    //       <PrivateRoute as={UserManagementPage} path="user-management" />
    //       <PrivateRoute as={CreateCourse} path="create-course" />
    //       <PrivateRoute as={TedSearch} path="ted-search" />

    //       <PrivateRoute as={MentalHealth} path="mental-health" />
    //       <PrivateRoute as={H5pApp} path="content-management" />

    //       <SignIn path="sign-in" />
    //       <SignIn path="signIn" />

    //       <PasswordReset path="password-reset" />
    //       <Redirect from="/passwordReset" to="/password-reset" />
    //       <SetNewPassword path="set-new-password" />
    //       <Redirect from="setNewPassword" to="set-new-password" />
    //       <PrivateRoute as={ProfilePage} path="profile" />
    //       <PrivateRoute as={ChatScreenComponent} path="profile/messages" />
    //       <OnBoarding path="on-boarding" />
    //       <OnBoarding path="onBoarding" />

    //       {/* <EmailActivationPrompt path="activation" /> */}
    //       <PrivateRoute as={EmailActivationPrompt} path="activation" />

    //     </>
    //   )}

    //   {/* <SignUp path="signUp" /> */}

    //   <NotFound default />
    // </Routes>
  );
}

export default AppRouter;
